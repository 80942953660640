<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="loading loading--box" v-if="loading"></div>
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>Study Room</h2>
          </div>
          <div class="studybox_h3_box">
            <div class="studybox_h3_box_in">
              <img src="/image/common/top_title_wood_bg02.png" style="float:left;"/>
              <div class="study-room-book-header">
                <span class="book-code">{{ study_room.content_name }}</span>
                <h3 class="book-title">{{ study_room.content_title }}</h3>
              </div>
              <img src="/image/common/top_title_wood_bg04.png" style="float:left;"/>
            </div>
          </div>
          <div class="studybox_h4_box">
            <h4>Listening & Reading</h4>
          </div>
          <section class="study_myinfo_list_area" style="height:90%;">
            <div class="mypage_scroll_content">
              <section class="study_record_box">
                <audio :ref="`audioPlayer_${content.id}`" :class="`audioPlayer_${content.id}`" style="display: none;" controls></audio>
                <audio controls v-if="file" id="player" style="display: none;">
                  <source :src="file" type="audio/mp3">
                  Your browser does not support the audio element.
                </audio>
                <!-- content -->
                <div class="record02_title_box">
                  <h4>문장녹음</h4>
                </div>
                <div class="record_btn_box">
                  <div v-if="!complete">
                    <ul>
                      <li class="record_btn_01 clickable" @click="play">
                        <div class="record_btn_title_area">문장듣기</div>
                        <div class="record_btn_click_area"><img src="/image/sub/study_record_btn04.png" alt="클릭 아이콘"/></div>
                      </li>
                      <li class="record_btn_02 clickable" :class="{ 'user_is_recording' : content.is_recording === true}" @click="recording">
                        <div class="record_btn_title_area">
                          <span v-if="content.is_recording">Stop</span>
                          <span v-else>녹음하기</span>
                        </div>
                        <div class="record_btn_click_area"><img src="/image/sub/study_record_btn04.png" alt="클릭 아이콘"/></div>
                      </li>
                      <li class="record_btn_03 clickable" :class="{'disable' : (hasRecording === false && hasPlayback === false)}" @click="playRecording">
                        <div class="record_btn_title_area">녹음듣기</div>
                        <div class="record_btn_click_area"><img src="/image/sub/study_record_btn04.png" alt="클릭 아이콘"/></div>
                      </li>
                    </ul>
                  </div>
                  <div v-else style="margin-top: -100px;">
                    <img src="/image/common/book-content-completed.png" class="book-content-completed">
                  </div>
                </div>
                <!-- //content -->
              </section>
              <div class="quiz_bottom_btn_box">
                <div class="study_result_quiz_num01 clickable" v-if="back" @click="loadContent(back, talkScheduleId, studyRoomId)">{{ back }}</div>
                <img src="/image/sub/reading_bottom_arr.png" alt="슬래쉬 아이콘" style="vertical-align:middle;margin:0 10px 0 10px;">
                <div class="study_result_quiz_num01 clickable" v-if="next" @click="loadContent(next, talkScheduleId, studyRoomId)">{{ next }}</div>
                <div class="study_result_quiz_num01 clickable" v-else @click="completed" style="width: 105px;border-radius: 10px;font-weight: normal;height: 40px;line-height: 38px;">Completed</div>
              </div>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'study-room.content', params: { talkScheduleId: talkScheduleId } }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>

import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import StudyRoom from '@/models/StudyRoom'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  },
  name: 'StudyRoom.Content.Type.ListenRec',
  data () {
    return {
      loading: true,
      complete: false,
      study_room: {},
      file: null,
      hasRecording: false,
      hasPlayback: false,
      playBack: document.createElement('AUDIO'),
      content: {
        is_recording: false
      },
      pagination: {},
      next: null,
      back: null,
      totalPage: 0,
      talkScheduleId: typeof this.$route.params.talkScheduleId !== 'undefined' ? this.$route.params.talkScheduleId : '',
      studyRoomId: typeof this.$route.params.studyRoomId !== 'undefined' ? this.$route.params.studyRoomId : '',
      page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1
    }
  },
  mounted () {
    this.loadContent(this.page, this.talkScheduleId, this.studyRoomId)
  },
  methods: {
    async loadContent (page, talkScheduleId, studyRoomId) {
      this.loading = true
      this.hasPlayback = false
      const searchParams = {}
      searchParams.page = page
      this.$router.replace({ query: searchParams })
      const response = await StudyRoom.viewContent({ page: page, talkScheduleId: talkScheduleId, studyRoomId: studyRoomId })
      this.content = response.data.data[0]
      this.file = response.data.data[0].content.file
      $('#player').src = this.file // eslint-disable-line
      $('#player').load() // eslint-disable-line
      this.pagination = response.data.meta.pagination
      this.next = this.pagination.next
      this.back = this.pagination.back
      this.study_room = response.data.meta.study_room
      if (this.content.has_recording) {
        this.hasPlayback = true
        this.playBack.setAttribute('src', this.content.file)
      }

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        this.setupMediaRecorder()
      } else {
        console.log('getUserMedia not supported on your browser!')
      }
      this.loading = false
    },
    async setupMediaRecorder () {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true
        })

        this.content.media_recorder = new MediaRecorder(stream)
        this.content.audio_player = this.$refs[`audioPlayer_${this.content.id}`]

        this.content.media_recorder.ondataavailable = e => {
          this.content.chunks.push(e.data)
        }

        this.content.media_recorder.onstop = e => {
          console.log('data available after MediaRecorder.stop() called.')
          this.content.audio_player.controls = true
          this.content.blob = new Blob(this.content.chunks, { type: 'audio/mp3' }) // 'type' : 'audio/ogg; codecs=opus'
          this.content.chunks = []
          this.content.audio_player.src = window.URL.createObjectURL(this.content.blob)
          this.uploadRecording(this.content)
          console.log('recorder stopped')
        }
      } catch (e) {
        console.log('The following getUserMedia error occured: ' + e.message)
      }
    },
    play () {
      $('#player')[0].play(); // eslint-disable-line
    },
    recording () {
      if (!this.content.is_recording) {
        this.hasPlayback = false
        this.hasRecording = false
        if (this.content.audio_player) {
          this.content.audio_player.pause()
        }
        this.content.is_recording = true
        this.content.media_recorder.start()
      } else {
        this.content.is_recording = false
        this.hasRecording = true
        this.content.media_recorder.stop()
      }
    },
    uploadRecording (content) {
      var formData = new FormData()
      formData.append('file', content.blob, 'recording_' + content.id + '.mp3')
      formData.append('talkScheduleId', this.talkScheduleId)
      formData.append('studyRoomId', this.studyRoomId)
      formData.append('contentId', content.id)
      StudyRoom.uploadRecording(formData)
    },
    playRecording () {
      if (!this.content.is_recording) {
        if (this.hasPlayback) {
          this.playBack.play() // eslint-disable-line
        } else {
          this.content.audio_player.play()
        }
      }
    },
    completed () {
      this.complete = true
      const self = this
      setTimeout(function () {
        self.$router.replace({ name: 'study-room.content', params: { talkScheduleId: self.talkScheduleId } })
      }, 2000)
    }
  }
}
</script>

<style scoped>

</style>
